import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 715.000000 524.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,524.000000) scale(0.100000,-0.100000)">
					<path d="M5135 4480 c-169 -46 -362 -128 -459 -194 -49 -33 -35 -33 91 3 133
39 299 66 348 57 112 -21 114 -137 5 -276 -122 -154 -354 -374 -655 -622 -98
-81 -295 -245 -317 -264 -34 -30 -39 -30 -86 -1 -20 13 -45 26 -54 30 -16 6
-18 21 -18 117 l0 110 -30 0 c-33 0 -33 1 -30 77 3 89 -4 106 -62 133 -29 14
-88 45 -132 71 -45 25 -115 64 -156 86 -41 22 -109 59 -151 82 -121 65 -144
62 -343 -48 -89 -49 -197 -108 -241 -131 -227 -122 -239 -137 -236 -267 1 -46
-1 -87 -3 -92 -3 -4 -44 12 -92 36 -47 23 -93 43 -103 43 -9 0 -93 -42 -187
-92 -93 -51 -187 -102 -209 -113 -21 -11 -56 -36 -77 -56 l-38 -36 0 -249 c0
-275 3 -292 66 -324 16 -8 63 -33 104 -56 153 -83 216 -118 258 -141 24 -13
50 -23 58 -23 22 0 17 -12 -29 -61 -138 -149 -253 -292 -333 -414 -62 -96 -83
-143 -84 -191 -1 -80 108 -92 270 -31 89 34 261 120 340 172 19 12 73 47 120
76 47 30 128 82 180 117 89 60 97 63 126 53 51 -18 76 -13 168 39 l88 49 28
-27 c15 -15 58 -43 96 -63 89 -46 195 -103 368 -197 122 -66 144 -75 191 -75
42 0 64 7 104 32 28 17 53 31 56 31 2 0 27 14 55 30 28 17 79 45 113 64 280
151 310 170 339 214 16 24 18 58 18 350 0 407 10 379 -160 469 -103 55 -250
137 -278 154 -27 17 -28 27 -4 36 18 7 280 178 320 210 13 10 41 32 62 48 21
17 50 39 65 50 147 106 457 374 602 521 137 138 211 236 252 331 39 90 40 134
5 162 -41 32 -196 28 -329 -9z m-1887 -927 l2 -173 35 0 35 0 2 173 3 172 80
0 80 0 3 -410 c1 -225 0 -420 -3 -433 -5 -22 -9 -23 -83 -20 l-77 3 -3 173 -2
172 -35 0 -35 0 0 -175 0 -176 -82 3 -83 3 -3 400 c-1 220 0 415 3 433 l6 33
77 -3 77 -3 3 -172z m-726 -247 l83 -43 5 -200 5 -200 29 -38 c19 -25 50 -47
88 -64 32 -14 58 -29 58 -33 0 -4 -25 -22 -55 -39 -71 -40 -85 -61 -85 -126
l0 -53 -57 -30 c-32 -16 -78 -41 -103 -55 -25 -14 -58 -25 -74 -25 -18 0 -100
39 -213 100 -101 55 -187 100 -191 100 -4 0 -16 13 -27 28 -18 25 -20 46 -23
245 l-3 218 34 33 c19 18 70 51 113 74 44 23 108 59 144 78 122 68 135 74 162
74 15 0 64 -20 110 -44z m1523 -343 c48 -25 75 -54 104 -108 20 -38 21 -53 21
-385 0 -390 -1 -393 -78 -462 -57 -51 -95 -60 -252 -56 l-135 3 -3 509 c-2
401 1 512 10 518 7 5 76 7 153 5 117 -3 146 -7 180 -24z m-1185 -292 c0 -30
-35 -65 -93 -94 -67 -33 -67 -33 -67 -4 1 32 20 53 81 86 64 35 79 38 79 12z
m125 -42 c17 -10 48 -28 70 -39 22 -11 67 -35 100 -53 l60 -34 3 -74 3 -75
-151 -102 -152 -102 -99 52 c-55 28 -105 60 -110 70 -5 10 -8 59 -7 110 l3 91
55 29 c100 54 151 90 166 119 17 34 19 34 59 8z m-317 -386 c7 -12 34 -33 60
-46 94 -48 132 -71 132 -78 0 -9 -145 -101 -293 -186 -166 -94 -300 -128 -342
-86 -42 41 10 171 146 364 88 126 92 130 199 188 l75 40 5 -87 c3 -48 11 -97
18 -109z m552 -16 c0 -30 -5 -35 -93 -81 -67 -35 -99 -47 -113 -42 -18 7 -18
9 6 24 14 10 61 45 105 78 44 34 83 59 88 57 4 -2 7 -19 7 -36z"/>
					<path d="M3110 3295 l0 -415 60 0 60 0 2 178 3 177 55 0 55 0 2 -160 c1 -88 2
-168 3 -178 0 -16 8 -18 58 -15 l57 3 0 410 0 410 -57 3 -58 3 -1 -163 c-1
-90 -1 -169 0 -175 2 -15 3 -15 -64 -16 l-50 -2 -3 178 -2 177 -60 0 -60 0 0
-415z"/>
					<path d="M3729 2958 c0 -2 0 -220 -1 -486 0 -324 3 -487 10 -494 7 -7 56 -9
134 -6 113 3 128 6 171 31 26 16 55 40 65 55 39 59 42 88 42 415 0 383 -1 389
-109 459 -44 27 -49 28 -178 28 -73 0 -133 -1 -134 -2z m261 -165 c20 -26 21
-34 18 -329 -3 -350 -2 -344 -90 -344 l-48 0 0 350 0 350 49 0 c42 0 53 -4 71
-27z"/>
					<path d="M3895 2787 c-3 -7 -4 -154 -3 -327 l3 -315 28 -3 c57 -7 57 -7 57
326 0 279 -2 306 -17 318 -23 17 -62 18 -68 1z"/>
					<path d="M2342 3829 l-52 -31 0 -59 0 -59 50 -25 c27 -14 55 -25 61 -25 7 0
32 12 56 28 l44 27 -3 60 -3 60 -50 27 -50 27 -53 -30z"/>
					<path d="M4995 3337 c-52 -29 -55 -33 -55 -89 0 -40 4 -50 29 -67 57 -41 81
-44 129 -20 55 29 64 47 56 106 -6 44 -10 50 -50 71 -54 27 -59 27 -109 -1z"/>
					<path d="M1400 1115 l0 -295 73 0 c87 1 132 21 159 70 22 43 24 146 3 196 -13
32 -13 38 0 57 10 15 15 46 15 98 0 63 -4 82 -23 110 -32 47 -61 59 -149 59
l-78 0 0 -295z m150 195 c14 -14 20 -33 20 -66 0 -64 -13 -84 -55 -84 l-35 0
0 78 c0 43 3 82 7 85 13 13 43 7 63 -13z m12 -283 c4 -20 4 -54 0 -74 -7 -35
-11 -38 -45 -41 l-37 -3 0 79 c0 43 2 81 5 84 3 3 20 2 37 -1 27 -6 33 -12 40
-44z"/>
					<path d="M1751 1333 c-21 -152 -60 -448 -63 -478 -3 -29 -1 -30 37 -30 38 0
40 2 43 32 4 39 22 55 59 51 24 -3 29 -8 33 -43 5 -39 6 -40 45 -40 36 0 40 2
39 25 -2 34 -63 509 -69 538 -5 19 -12 22 -59 22 l-54 0 -11 -77z m84 -270 l7
-73 -27 0 c-25 0 -26 2 -21 38 3 20 8 59 12 86 9 63 20 43 29 -51z"/>
					<path d="M1986 1368 c-3 -24 -4 -155 -1 -293 l4 -250 58 -3 c76 -4 125 11 157
49 23 28 26 39 26 115 0 47 -5 94 -11 105 -8 15 -7 32 5 65 10 31 13 64 9 107
-10 117 -45 147 -166 147 l-75 0 -6 -42z m145 -48 c16 -9 19 -22 19 -73 0 -70
-10 -87 -52 -87 l-28 0 0 85 c0 79 1 85 21 85 11 0 29 -5 40 -10z m11 -264 c4
-6 8 -39 8 -72 0 -66 -6 -74 -56 -74 -23 0 -24 2 -24 83 l0 84 33 -5 c17 -3
35 -10 39 -16z"/>
					<path d="M2345 1388 c-3 -13 -14 -93 -25 -178 -11 -85 -26 -200 -35 -255 -21
-142 -22 -135 25 -135 41 0 43 2 52 65 3 16 11 20 38 20 33 0 36 -2 44 -42 9
-41 11 -43 48 -43 33 0 38 3 38 23 0 12 -16 139 -35 282 -19 143 -35 266 -35
273 0 8 -18 12 -55 12 -48 0 -55 -3 -60 -22z m79 -360 c6 -36 5 -38 -20 -38
l-27 0 7 77 c9 96 18 113 28 48 3 -27 9 -67 12 -87z"/>
					<path d="M2735 1397 c-3 -7 -4 -138 -3 -292 l3 -280 43 -3 42 -3 0 128 0 128
40 0 39 0 3 -125 3 -125 40 0 40 0 2 293 1 292 -44 0 -44 0 0 -125 0 -125 -40
0 -40 0 0 125 0 125 -40 0 c-24 0 -43 -5 -45 -13z"/>
					<path d="M3097 1388 c-6 -23 -65 -477 -70 -533 -2 -29 0 -30 38 -30 39 0 40 1
45 40 5 39 6 40 45 40 40 0 40 0 43 -42 3 -43 3 -43 42 -43 21 0 41 3 43 8 3
5 -36 314 -68 540 l-6 42 -54 0 c-46 0 -54 -3 -58 -22z m82 -380 c1 -13 -7
-18 -25 -18 -26 0 -26 0 -20 58 4 31 10 73 14 92 6 32 7 28 18 -40 7 -41 13
-83 13 -92z"/>
					<path d="M3390 1397 c-33 -17 -70 -74 -70 -109 0 -72 22 -120 86 -186 92 -97
111 -182 40 -190 -20 -3 -28 2 -36 23 -5 14 -9 31 -7 38 1 7 -14 13 -40 15
-42 3 -43 2 -43 -27 0 -92 78 -157 159 -133 57 18 84 52 89 116 6 74 -12 120
-71 180 -65 66 -87 103 -87 148 0 39 21 60 50 50 21 -7 30 -23 30 -54 0 -26 3
-28 40 -28 l40 0 0 41 c0 22 -8 54 -17 70 -31 51 -109 74 -163 46z"/>
					<path d="M3679 1395 c-42 -23 -59 -58 -59 -122 0 -73 25 -128 76 -172 53 -46
84 -98 84 -143 0 -28 -5 -38 -20 -43 -38 -12 -60 3 -60 41 0 33 -1 34 -39 34
-30 0 -40 -4 -44 -20 -15 -56 39 -131 103 -145 39 -9 113 26 128 61 16 34 15
128 -2 167 -7 18 -39 56 -70 85 -59 57 -84 110 -72 155 13 55 76 40 76 -18 0
-34 1 -35 39 -35 44 0 54 13 44 63 -17 89 -107 134 -184 92z"/>
					<path d="M3977 1388 c-6 -24 -65 -480 -70 -533 -2 -29 0 -30 38 -30 32 0 40 4
43 20 10 65 11 66 50 63 36 -3 37 -4 40 -45 3 -42 4 -43 41 -43 21 0 40 2 43
5 5 6 0 48 -42 355 -17 118 -30 218 -30 223 0 4 -24 7 -54 7 -48 0 -54 -3 -59
-22z m82 -385 c1 -7 -10 -13 -25 -13 -26 0 -26 0 -19 63 12 123 18 130 31 42
7 -44 13 -86 13 -92z"/>
					<path d="M4202 1118 l3 -293 40 0 40 0 0 128 c0 70 2 127 5 127 3 0 17 -46 32
-102 40 -157 40 -158 88 -158 l40 0 0 295 0 295 -40 0 -40 0 0 -132 c0 -72 -3
-129 -6 -125 -3 3 -20 61 -38 129 l-33 123 -46 3 -47 3 2 -293z"/>
					<path d="M4872 1118 l3 -293 76 1 c90 1 125 17 150 64 17 30 19 59 19 228 0
211 -5 233 -59 271 -24 17 -44 21 -110 21 l-81 0 2 -292z m152 196 c13 -13 16
-45 16 -190 0 -102 -4 -183 -10 -195 -8 -13 -21 -19 -45 -19 l-35 0 0 203 c0
112 3 207 7 210 12 13 51 7 67 -9z"/>
					<path d="M5236 1401 c-5 -7 -46 -306 -72 -529 l-7 -53 39 3 c30 2 40 8 42 23
9 64 11 66 50 63 33 -3 37 -6 40 -33 5 -42 17 -53 55 -51 28 1 32 5 30 26 -6
58 -64 507 -69 533 -5 25 -9 27 -54 27 -27 0 -51 -4 -54 -9z m70 -340 c7 -67
6 -71 -14 -71 -31 0 -33 11 -20 94 14 89 25 82 34 -23z"/>
					<path d="M5417 1383 c-8 -40 1 -52 41 -55 l37 -3 5 -250 5 -250 35 0 35 0 5
250 5 250 38 3 c36 3 37 4 37 43 l0 39 -119 0 -119 0 -5 -27z"/>
					<path d="M5736 1398 c-3 -7 -14 -89 -26 -183 -12 -93 -28 -217 -36 -274 -7
-57 -11 -107 -9 -111 2 -4 19 -7 37 -6 29 1 34 5 44 41 10 36 14 40 47 43 35
3 36 3 42 -42 7 -46 8 -46 46 -46 30 0 39 4 39 18 0 9 -11 100 -26 202 -14
102 -31 226 -38 275 l-12 90 -52 3 c-36 2 -53 -1 -56 -10z m70 -310 c4 -40 8
-77 8 -83 1 -5 -12 -11 -28 -13 -22 -3 -27 0 -23 11 3 8 9 47 13 86 3 39 10
71 14 71 4 0 11 -33 16 -72z"/>
					<path d="M4580 1120 l0 -40 80 0 80 0 0 40 0 40 -80 0 -80 0 0 -40z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
